<template>
  <div class="my-8">
    <div class="d-flex">
      <div class="mr-3">
        <v-select v-model="sortBy" return-object solo flat item-text="text" item-value="value" :items="sortItems"/>
      </div>
      <div>
        <v-text-field placeholder="Search" solo v-model="search"/>
      </div>
    </div>
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="#008eef" />
    </div>
    <div v-else>
      <v-card v-for="(test, i) in tests" :min-height="100" :key="i">
        <v-card-text style="min-height: 100px" class="d-flex align-center justify-space-between">
          <div class="d-flex flex-wrap tw-w-full sm:tw-w-1/2 justify-space-between">
            <div class="mb-3 mb-sm-0">
              <h3 class="test__title">{{test.title}}</h3>
              <small>{{test.category}}</small>
            </div>
            <div class="d-flex align-center extra__info">
              <span class="mr-2">{{test.number_of_questions}} Questions</span>
              <span class="d-flex align-center">
              <vue-feather size="16" class="mr-1" type="clock"/>
              {{test.duration}} mins
            </span>
            </div>
          </div>
          <v-btn icon>
            <v-icon color="black">mdi-dots-vertical</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import {getSavedTestForJob} from "@/services/api/APIService";

export default {
  name: "SavedTests",
  data: () => ({
    sortBy: {
      text: 'Sort By All',
      value: 'all'
    },
    loading: false,
    sortItems: [
      {
        text: 'Sort By All',
        value: 'all'
      }
    ],
    search: '',
    tests: []
  }),
  methods: {
    async getSavedTests() {
      const {id} = this.$route.params
      const {data} = await getSavedTestForJob(id)
      this.tests = data.map(test => ({...test, duration: test.topic_time, category: test.description}))
    }
  },
  async created() {
    this.loading = true
    try {
      await this.getSavedTests()
    } catch (e) {
      this.$displaySnackbar({message: e.response.detail, success: false})
    } finally {
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
.test__title {
  font-weight: 700;
  color: #1E323F;
  font-size: 18px;
  font-family: "DM Sans", serif;

  &+small {
    color: #9CABB5;
    font-weight: 400;
    font-family: "DM Sans", serif;
  }
}

.extra__info {
  span {
    font-family: "DM Sans", serif;
    font-weight: 400;
    color: #475661;
  }
}

</style>